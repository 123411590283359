import React from 'react'

import {
    Banner,
    Title,
    SubTitle
} from './styled'

import { CallToAction } from '../../../pageStyles'

const Promo = ({title, description, link, label}) => (
    <>
        <Banner>
                <Title>{title}</Title> 
                <SubTitle>{description}</SubTitle>
                <CallToAction href={link}>{label}</CallToAction>
        </Banner>
    </>
)

export const TelegramChannel = () => (
    <Promo
        title="Le migliori offerte FPV"
        description="Grazie al nostro canale Telegram riceverai immediatamente le ultime offerte e i migliori coupon"
        link="https://t.me/fpv_coupons"
        label="Entra Ora!"
    />
)

export const TelegramGroup = () => (
    <Promo
        title="NorthFPV Community"
        description="Entra a far parte della più grande community italiana di droni FPV"
        link="https://t.me/north_fpv"
        label="Unisciti Ora!"
    />
)

export const SetupAssistant = () => (
    <Promo
        title="Setup Assistant"
        description="Il primo configuratore FPV che ti aiuta a scegliere i componenti adatti a te"
        link="/fpv-assistant"
        label="Provalo Ora!"
    />
)