import React from 'react'
import { Checkout } from '../../components/common'

const BackgroundVideo = ({data}) => {
    const videos = data.videos;
        return (
        <div className="luts-container" >
            <video autoPlay="autoplay" loop="loop" muted poster={data.poster.childImageSharp.fixed.srcWebp} className="luts-video" >
                <source src={videos.first.path} type="video/webm" />
                <source src={videos.second.path} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="luts-content">
                <div className="luts-sub-content" >
                    <h1 className="luts-banner-title">LUTs di NorthFPV</h1>
                    <Checkout buttonLabel="Scarica Ora" price="price_1Ho7qyAafUY8FLlUGxUnF8ls" className="my-btn btn-outline-dark" cancelPath="luts" />
                </div>
            </div>
        </div>)
}


export default BackgroundVideo;
