import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const SocialIcon = styled.i`
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: ${props => props.color};
    width: 2rem;
    height: 2rem;

    &:hover {
        color: ${colors.personality};
    }
`;

export const SocialName = styled.a`
    margin-right: 2.5rem;

    ${media.medium`
        margin-right: 1.5rem;
    `}
`;