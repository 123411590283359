import React from 'react'
import { Link } from 'gatsby'
import { Social } from '../Social'

import { SubHeading, Paragraph, Container, Row } from './styled'

export const Footer = () => (
    <Container>
        <Row>
            <div className="site-foot-nav-left">
                    
                    <SubHeading>Cos'è NorthFPV?</SubHeading>
                    <Paragraph>
                        North FPV è una community FPV Italiana, creata per insegnare e condividere le emozioni del volo in FPV.<br />
                        Cerchiamo di rispondere a ogni domanda che riceviamo. Aiutiamo quasi tutti senza richiedere alcun pagamento.
                    </Paragraph>
                    <Link to="/">NorthFPV</Link> © 2020  &middot; <Link to="/supportaci">Link Affiliati</Link> &middot; <Link to="/supportaci">Supportaci</Link>

                </div>
                <div className="site-foot-nav-right">
                    <SubHeading>Seguiteci su:</SubHeading>
                    <Social isWhite={true} />
                </div>
        </Row>
    </Container>
)