import React from 'react'
import { OutboundLink } from 'gatsby-plugin-gtag'

const BuyCard = ({ product }) => {
    if (!product) return null
    return (
        <>
        <div>
            <header className="product-card-header">
                <div className="product-card-image" style={{backgroundImage: `url(${product.image})`,}}/>
            </header>
            <h3 className="product-card-title">{product.title || product.category}</h3>
            <OutboundLink className="product-cta" href={product.link || product.links[0].url} target="_blank" rel="nofollow">Compra {product.name}</OutboundLink>
            { product.description ? 
            <details>
                <summary>Più informazioni</summary>
                <p className="product-card-excerpt" dangerouslySetInnerHTML={{ __html: product.description }}/>
            </details>
            : null}
            {product.links && product.links.length > 1 ? 
                <div>
                    <h6>Puoi comprarlo anche su: </h6>
                    <ul>
                    {product.links.slice(1).map((link) => {
                        return <li><OutboundLink href={link.url} target="_blank" rel="nofollow">{link.store}</OutboundLink></li>
                        }
                    )}
                    </ul>
                </div>
            : null}
        </div>
        </>   
    );
}

export default BuyCard;
