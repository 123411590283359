import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { MenuItem } from './styled';

export const BurgerMenu = () => {
    return (
        <Menu right>
            <MenuItem href="/prodotti-consigliati">Prodotti Consigliati</MenuItem>
            <MenuItem href="/mappa-fpv-italia">Mappa FPV Italia</MenuItem>
            <MenuItem href="/club">Club</MenuItem>
            <MenuItem href="/piloti">Piloti</MenuItem>
            <MenuItem href="/tutorial">Tutorial</MenuItem>
            <MenuItem href="/news">News</MenuItem>
            <MenuItem href="/supportaci">Supportaci</MenuItem>
            <MenuItem href="/chi-siamo">Chi Siamo</MenuItem>
        </Menu>
    );
}