import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const SubHeading = styled(fonts.SubHeading)`
    color: ${colors.white};
    opacity: 0.7;
`;

export const Paragraph = styled(fonts.Body)`
    color: ${colors.white};
    font-size: 14px;
    line-height: 18px;
    opacity: 0.7;
`;

export const Container = styled.footer`
    background: #000;
    color: rgba(255, 255, 255, 0.7);
    padding: 4rem 0 10rem 0;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
    padding: 0 4vw;

    ${media.medium`
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 4vw;
        flex-direction: row;
    `}
`;

export const Left = styled.div`

`;
