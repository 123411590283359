import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js/pure"

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51HkSnhAafUY8FLlUead2rJj74bOpZ1i2dLll7X6pylbHXBe6y3qMb9EwGLWxr15rXCYCZudjTlEnXgC5LYnBYSWz00jhScO5iL")
  }
  return stripePromise
}

const Checkout = ({buttonLabel, price, cssClass, cancelPath}) => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)
    const stripe = await getStripe()
    const result = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: price, quantity: 1 }],
      successUrl:  `${window.location.origin}/grazie`,
      cancelUrl: `${window.location.origin}/${cancelPath}`,
    })
    // console.log(result)
    if (result.error) {
      console.warn("Error:", result.error)
      setLoading(false)
    }
  }

  return (
    <button
      disabled={loading}
      className={cssClass ? cssClass : 'my-btn'}
      onClick={redirectToCheckout}
    >
      {buttonLabel ? buttonLabel : 'Acquista'}
    </button>
  )
}

export default Checkout