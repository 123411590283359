import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Banner = styled.div`
    width: 80%;
    margin: 0;
    padding: 5vw 0;

    ${media.medium`
        width: 50%;
    `};
`;

export const Title = styled(fonts.Billboard)`
    margin: 0;
    padding: 0;
    color: ${colors.white};
`;

export const SubTitle = styled(fonts.SubHeading)`
    margin: 1rem 0 3rem 0;
    padding: 0;
    font-weight: normal;
    opacity: 0.7;
    color: ${colors.white};
`;
