import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';


export const MenuItem = styled.a`
    flex: 0 0 auto;
    margin: 5px;
    padding: 5px;
    min-height: 40px;
    text-align: center;
    color: ${colors.black};
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        padding: 10px;
        font-size: 2rem;
    }
`;
