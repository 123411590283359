import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { PostCard } from '.';

import {
  Heading,
  Center,
  Grid,
} from '../../pageStyles'

const Products = () => {

  const data = useStaticQuery(graphql`
    {
      allProductsJson(sort: {fields: order}) {
        edges {
          node {
            header_image
            slug
            title
          }
        }
      }
    }
    `)


  const posts = data.allProductsJson.edges
  return (
    <>
      <Center>
        <Heading>I nostri consigli</Heading>
      </Center>
      <Grid>
        {posts.map(({ node }) => {
          return <PostCard post={node} />
        })}
      </Grid>
    </>);
}


export default Products;

