import styled from 'styled-components';
import { colors, fonts, media } from '../utils';

export const Container = styled.div`
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 4vw;
`;

export const Row = styled.div`
    max-width: 1250px;
    margin: 2rem auto;
    padding: 0 4vw;
    display: flex;
    flex-direction: column;

    ${media.medium`
        flex-direction: row;
        margin: 0 auto;
    `}
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: 50%;
`;

export const Grid = styled.section`
    display: grid;
    justify-content: space-between;
    grid-gap: 3rem;
    grid-template-columns: 1fr;

    ${media.medium`
        grid-template-columns: 1fr 1fr 1fr;
    `}
`;

export const Paragraph = styled(fonts.Body)`
    color: ${colors.darkGray};
`;


export const Heading = styled(fonts.Heading)`
    color: ${colors.black};
`;

export const SubHeading = styled(fonts.SubHeading)`
    color: ${colors.black};
`;

export const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const ListItem = styled.li`
`;

export const Link = styled.a`
    font-size: 18px;
    line-height: 26px;
`;

export const BackgroundImage = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-image: url("${props => props.src}");
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.25);
`;

export const ImageHeading = styled(fonts.Heading)`
    color: ${colors.white};
    margin: 25% 0;
    padding: 0;
    font-size: 4rem;
    line-height: 1.3em;
    text-align: center;
`;

export const Center = styled.div`
    justify-content: center;
    text-align: center;
`;

export const Button = styled.a`
    display:inline-block;
    padding:0.5em 1.2em;
    border-radius: 2rem;
    margin:0 33%;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: bold;
    font-size: large;
    background-color: ${colors.personality};
    color: ${colors.white} !important;
    text-align:center;
    transition: all 0.2s;

    cursor: pointer;

    &:hover {
        font-weight: bolder;
        text-decoration: none;
    }
`;

export const CallToAction = styled(Button)`
    text-transform: uppercase;
    margin: 0;
`;

export const CenterButton = styled(Button)`
    margin: 0;
    width: 100%;
    font-size: 2rem;

    ${media.medium`
        margin: 0 30%;
        width: 40%;
    `}
`;


export const AlignStart = styled.div`
    align-items: flex-start;
`;

export const Icon = styled.i`
    font-size: 4rem;
    margin-bottom: 2rem;
    transition: all 0.5s;
    color: ${colors.white};
    width: 10rem;
    height: 10rem;
    padding: 3rem 2.5rem;
    border-radius: 50%;
    background: linear-gradient(to right, ${colors.personality} 0%, ${colors.personality} 100%);
    box-shadow: 10px 10px 10px rgba(0,0,0,.05);
`;

export const SmallIcon = styled.i`
    font-size: 2rem;
    margin-bottom: 2rem;
    transition: all 0.5s;
    color: ${colors.white};
    width: 4rem;
    height: 4rem;
    padding: 3rem 2.5rem;
`;

export const Divider = styled.hr`
    width: 40%;
    position: relative;
    display: block;
    margin: 4rem auto;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid ${colors.personality};
`;

export const MutedText = styled(Paragraph)`
    color: ${colors.lightGray};
`;

export const SideHighlight = styled.div`
    border-left: 5px solid ${colors.personality};
    padding: 0 2rem;
`