import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { Footer } from './Footer'
import { BurgerMenu } from './Menu'

// Styles
import '../../styles/app.css'


const Layout = ({ data, children }) => {
    return (
            <>
            <Helmet>
                <html lang="it" />
                <meta charset="UTF-8" />
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="it_IT"/>
            </Helmet>

            <div className="viewport" style={{minHeight: 0}}>
            <div className="viewport-top">
                    {/* The main header section on top of the screen */}

                    <header className="landing-head">
                        <div className="overlay"></div>
                        
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-nav-left">
                                    <Link to="/">
                                        <img src="/images/logo.png" width="100" height="90"/>
                                    </Link>
                                </div>
                                <div className="site-nav-right">
                                    <BurgerMenu/>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                    <main className="site-main-landing">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    <Footer/>
                </div>

        </>)
}

export default Layout;
