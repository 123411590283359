import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SocialIcon, SocialName } from './styled'

export const Social = (props) => {
    const isWhite = props.isWhite;
    const data = useStaticQuery(graphql`
        {
            allSite {
                edges {
                node {
                    siteMetadata {
                    social {
                        facebook
                        instagram
                        tiktok
                        youtube
                        discord
                    }
                    }
                }
                }
            }
            }  
        `);

    const meta = data.allSite.edges[0].node.siteMetadata
    return <>
        <SocialName href={meta.social.youtube} target="_blank" rel="noopener noreferrer"><SocialIcon className="fab fa-youtube" color={isWhite ? 'white' : 'black'} /></SocialName>
        <SocialName href={meta.social.instagram} target="_blank" rel="noopener noreferrer"><SocialIcon className="fab fa-instagram" color={isWhite ? 'white' : 'black'} /></SocialName>
        <SocialName href={meta.social.facebook} target="_blank" rel="noopener noreferrer"><SocialIcon className="fab fa-facebook-square" color={isWhite ? 'white' : 'black'} /></SocialName>
        <SocialName href={meta.social.discord} target="_blank" rel="noopener noreferrer"><SocialIcon className="fab fa-discord" color={isWhite ? 'white' : 'black'} /></SocialName>
        <SocialName href={meta.social.tiktok} target="_blank" rel="noopener noreferrer"><SocialIcon className="fab fa-tiktok" color={isWhite ? 'white' : 'black'} /></SocialName>
    </>
}

