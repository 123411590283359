import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactStars from 'react-rating-stars-component'


const Testimonials = ({ post }) => {
    return (
        <Carousel
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={1500}>
        <div>
        
          <div className="myCarousel">
            <h3>Marco FPV</h3>
            <div className="stars-review"><ReactStars count={5} value={5} size={15} edit={false}/></div>
            <h4>Pilota Cinematic</h4>
            <p>
              Buon pacchetto, migliorano i video dando uno stile più "cinematico", veramente consigliati
            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <h3>Andrea</h3>
            <div className="stars-review"><ReactStars count={5} value={5} size={15} edit={false}/></div>
            <h4>Pilota Freestyle</h4>
            <p>
              Semplicissimi da usare e ad un prezzo veramente ottimo, li consiglio per chiunque voglia avere un'immagine con effetto wow. Li ho provati su Mac sia su FCPX che su Davinci Resolve
            </p>
          </div>
        </div>

        <div>
          <div className="myCarousel">
            <h3>Mirko</h3>
            <div className="stars-review"><ReactStars count={5} value={4} size={15} edit={false}/></div>
            <h4>Pilota Freestyle e Cinematic</h4>
            <p>
              Pacchetto molto bello! Una volta applicati rendono i video veramente belli da vedere. L'unica pecca è che ce ne sono pochi.
            </p>
          </div>
        </div>
      </Carousel>
    );
}

export default Testimonials;
