import React from 'react'
import { Link } from 'gatsby'

const PostCard = ({ post }) => {
    return (
        <Link to={`https://northfpv.com/${post.slug}`} className="post-card">
            <header className="post-card-header">
                {post.header_image &&
                    <div className="post-card-image" style={{
                        backgroundImage: `url(${post.header_image})` ,
                    }}>
                        <h2 className="post-card-title">{post.title}</h2>
                        </div>}
                
            </header>
            { post.featured ?
            <section className="post-card-excerpt">{post.intro}</section>
            : null
            }
            
        </Link>
    );
}

export default PostCard;
