import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { SEO } from '.'
import { Footer } from './Footer'
import { BurgerMenu } from './Menu'
import { SetupAssistant } from './Promo'

const Layout = ({ data, children, isHome, bgImage, promotion }) => {
    const result = useStaticQuery(graphql`
    query {
        bg: file(relativePath: {eq: "site_banner.png"}) {
            childImageSharp {
              fixed(width: 1080, height: 720) {
                  srcWebp
              }
            }
          }
      }`);

    return (
            <>
            <Helmet>
                <html lang="it" />
                <meta charset="UTF-8" />
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="it_IT"/>
                <meta property="og:description" content="La più grande community italiana dedicata al mondo dei droni FPV. Consigli sugli acquisti, tutorial e recensioni per condividere la passione di volare con droni FPV."/>   
            </Helmet>

            <div className="viewport">

                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    <header>
                            <div className="site-mast">
                                <div className="site-nav-left">
                                    <Link to="/">
                                        <img src="/images/logo.png" width="100" height="90"/>
                                    </Link>
                                </div>
                                <div className="site-nav-right">
                                    <BurgerMenu/>
                                </div>
                            </div>
                            <div className="main-banner" style={{ backgroundImage: `url(${bgImage ? bgImage : result.bg.childImageSharp.fixed.srcWebp})`}}>
                                <div className="site-head">
                                    {isHome ? <SetupAssistant/>
                                        :
                                            promotion ? promotion : <div style={{height: "400px"}}></div>
                                        }
                                </div>
                            </div>
                    </header>

                    <main className="site-main">
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    <Footer/>
                </div>
            </div>
        </>
          )

}

export default Layout
